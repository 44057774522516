<template>
  <div class="local minbox">
    <!-- 头部通用区域 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!--    banner-->
    <section class="solution-banner">
      <div class="main-container">
        <div class="inner">
          <div class="title1">
            地方政府综合金融服务平台
          </div>
          <div class="title2">
            Local Comprehensive Financial Service Platfrom
          </div>
        </div>
      </div>
    </section>

    <section class="main-container">
      <div class="section-name">
        金融服务平台介绍
      </div>

      <div class="section-des txt-center">
        华炫鼎盛自2019年以来，随着完成“国家产融合作平台”、“工业文化产融平台”、大型核心企业“信票流转与融资平台”等国家级与央企级的产融合作平台布局，便积极将产融合作理念带到地方，“济南高新区产融平台”、大同市“民营中小企业综合服务平台”、青岛市“阳光产融平台”等平台相继落地，协助地方政府强化当地的产业链，优化地方营商环境，提供产业链上下游各企业增值服务，通过大数据与人工智能技术协助资金方进行风控建模，降低融资企业的信息不对称，促进银行等金融机构支持实体经济的发展。
      </div>
      <div class="show-img">
        <img src="../../assets/solutionImg/local_img.png" alt="">
      </div>
    </section>

    <!-- 底部公共区域 -->
    <section class="boxContentFooter">
      <FooterAll />
    </section>
  </div>
</template>
<script>
  import Navbar from '../../components/public/NavBar'
  import FooterAll from '../../components/public/footerAll'
  export default {
    data(){
      return{
        activeIndex: '1'
      }
    },
    components: {
      Navbar,
      FooterAll,
    },
  }
</script>
<style lang="scss" scoped>
  @import "./solution.scss";
</style>
<style lang="scss" scoped>
.show-img{
  width: 1016px;
  height: 675px;
  margin: 40px auto 60px;
  img{
    width: 100%;
  }
}
  .local{
    .solution-banner{
      background-image: url("../../assets/solutionImg/local_banner.png");
    }
  }
</style>
